import React, { Component } from "react"
import PropTypes from "prop-types"
class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="product-terms page-section">
          <div className="content-section content-section--white">

            <p>
              <b><a href="#english-terms">Scroll down for the product terms and conditions in English</a></b>
            </p>
            <p>
              Kaikkia lippuja ja maksuja koskevat seuraavat yleiset tuote-ehdot:
            </p>

            <ol>
              <li>Kaikki liput ja maksut ovat henkilökohtaisia.</li>
              <li>Kaikki liput ovat voimassa rajallisen ajan ja niiden voimassaoloa voidaan pidentää ainoastaan lääkärintodistuksella.</li>
              <li>Kausikohtaisten lippujen voimassaoloaikaa ei voi pidentää.</li>
              <li>Emme hyvitä käyttämättä jääneitä maksuja/lippuja.</li>
              <li>Viikkotuntien lipuilla ei voi maksaa leirejä, tiiviskursseja, workshopeja tai koulutusohjelmia.</li>
              <li>Leirien, tiiviskurssien, workshopien ja koulutusohjelmien maksuilla ei voi maksaa viikkotunteja, ellei toisin ilmoiteta.</li>
              <li>Koulutusohjelmien maksuilla ei voi maksaa viikkotunteja, leirejä, tiiviskursseja tai workshopeja, ellei toisin ilmoiteta.</li>
              <li>Koska Tapanilan pop-up -pisteellä ei ole vastaanottoa, ei lisämaksujen maksaminen ole siellä mahdollista. Näin ollen lyhyempien tuntien lippuja ei voi käyttää pidemmillä tunneilla Tapanilassa.</li>
              <li>Mikäli tanssikoulu joudutaan sulkemaan pandemian tai vastaavan ylivoimaisen esteen vuoksi, ei ostettuja lippuja hyvitetä, vaan niiden voimassaoloaikaa jatketaan tai pitämättä jääneet tunnit korvataan muulla tavoin.</li>
              <li>Ilmoittautumisen viikkotunnille voi vahvistaa kertalipulla, sarjalipulla, kausimaksulla tai Anytime-lipulla. Lue lisää ilmoittautumisen vahvistamisesta kotisivuiltamme: <a target="_blank" href="www.stepupschool.fi/ilmoittautumisohje/">www.stepupschool.fi/ilmoittautumisohje/</a></li>
              <li>Ilmoittautumisen leireille, tiiviskursseille ja workshopeille voi vahvistaa vain näiden maksulla.</li>
            </ol>
            <p>
              Lipuilla ja maksuilla on myös tuotekohtaisia ehtoja:
            </p>
            <h4>
              KERTALIPPUJEN TUOTEKOHTAISET EHDOT
            </h4>
            <p>
              Kertalipun hinta määräytyy tunnin keston mukaan. Kertalippuun on määritelty, minkä pituiselle tunneille se käy maksuksi. Halutessasi voit käyttää lippua myös lyhyemmällä tunnilla. Voit muuttaa kertalipun pidemmän tunnin lipuksi maksamalla hinnanerotuksen vastaanottoon tunnille tulon yhteydessä.
            </p>
            <p>

              Kertalippu on voimassa 365 päivää ostopäivästä. Kertalipulla voit vahvistaa yhden ilmoittautumisen.
            </p>
            <h4>
              SARJALIPPUJEN TUOTEKOHTAISET EHDOT
            </h4>
            <p>
              Sarjalipun hinta määräytyy tunnin keston ja lipussa olevien kertojen mukaan. Sarjalippuun on määritelty, minkä pituisille tunneille se käy maksuksi. Sarjalippu käy maksuvälineenä kaikilla tunneilla, mutta pidemmille tunneille on lisäksi maksettava tuntien kertamaksujen välinen erotus vastaanottoon tunnille tulon yhteydessä.
            </p>
            <p>
              Sarjalippu on voimassa kuusi kuukautta käyttöönottopäivästä lähtien, kuitenkin enintään 365 päivää ostopäivästä. Sarjalipun käyttöönottopäivän jälkeisessä voimassaoloajassa huomioidaan automaattisesti ne päivät, jolloin tanssikoulu on suljettu. Sarjalipulla voit vahvistaa niin monta ilmoittautumista, kuin lipussa on käyntikertoja jäljellä.
            </p>
            <h4>
              KAUSIMAKSUJEN TUOTE­KOHTAISET EHDOT
            </h4>
            <p>
              Kausimaksun hinta määräytyy tunnin keston, ostohetken ja oppilaan kausimaksujen määrän mukaan. Kausimaksu on myynnissä kauden viisi ensimmäistä viikkoa ja sen hinta alenee viikoittain puolikkaan kertalipun verran. Usean kausimaksun ostavalle oppilaalle myönnämme henkilökohtaisen kausimaksualennuksen siten, että kauden ensimmäinen kausimaksu on täysihintainen ja muista kausimaksuista myönnämme 15 %:n alennuksen.
            </p>
            <p>

              Kausimaksulla voi vahvistaa ilmoittautumisen sille tunnille, jota kausimaksu koskee. Kausimaksu kattaa kyseisen tunnin kauden loppuun asti ja ilmoittautuminen tälle tunnille pysyy voimassa oppilaan poissaoloista huolimatta. Kausimaksua ei voi siirtää tunnilta toiselle. Kausimaksun ostamalla oppilas sitoutuu käymään kyseisellä tunnilla kauden loppuun asti.
            </p>
            <p>
              Kausimaksun maksaneet oppilaat voivat korvata kauden aikana korkeintaan viisi poissaoloa/kausimaksu toisella saman hintaisella tai edullisemmalla tunnilla. Poissaolo on mahdollista korvata myös kalliimmalla tunnilla maksamalla tuntien kertamaksujen välinen erotus vastaanottoon tunnille tulon yhteydessä. Poissaolot on korvattava ennen kyseisen kauden päättymistä. Viisi korvauskertaa ovat asiakkaan vapaasti käytettävissä itse valittavana ajankohtana ko. kauden aikana. Kauden aikana olevien pyhäpäivien, joiden aikana tunteja ei järjestetä (esim. pyhäinpäivä, itsenäisyyspäivä, vappu ja pääsiäinen), korvauskerrat sisältyvät yllämainittuun viiteen kertaan.
            </p>
            <p>
              Korvaavalle tunnille ilmoittaudutaan vastaanotossa tunnille tulon yhteydessä. Tapanilassa korvaavalle tunnille ilmoittaudutaan opettajalle. Useamman kuin viiden poissaolokerran korvaamista varten on toimitettava lääkärintodistus.
            </p>
            <h4>
              ANYTIME-LIPPUJEN TUOTEKOHTAISET EHDOT
            </h4>
            <p>
              Anytime-lipuilla voi käydä kaikilla avoimilla viikkotunneilla. Koko kauden Anytime-liput ovat voimassa kyseisen kauden loppuun saakka. Viikon ja kuukauden Anytime-liput ovat voimassa 7 päivää tai 30 päivää käyttöönottopäivästä alkaen, kuitenkin enintään 365 päivää ostopäivästä. Viikon ja kuukauden Anytime-lippujen voimassaoloajoissa on valmiiksi huomioitu ne päivät, jolloin tanssikoulu on suljettu.
            </p>
            <p>
              Anytime-lipuilla voi vahvistaa ilmoittautumisen kaikille haluamilleen tunneille. Kauden Anytime-lipuista voidaan hyvittää lääkärintodistuksella vähintään 10 vuorokauden yhtäjaksoinen poissaolo.
            </p>
            <h4>
              LEIRIEN JA RUOKATARJOILUN SISÄLTÄVIEN WORKSHOPIEN TUOTE- JA PERUUTUS EHDOT
            </h4>
            <p>
              Leiri/workshop-maksu (myöhemmin maksu) oikeuttaa osallistumaan kyseiselle leirille/workshopille (myöhemmin tapahtuma). Maksulla voi vahvistaa ilmoittautumisen valittuun tapahtumaan.
            </p>
            <p>
              Ilmoittautuminen on peruutettava sähköpostitse osoitteeseen <a target="_blank" href="mailto:school@stepup.fi">school@stepup.fi</a>. Maksu palautetaan kokonaisuudessaan, mikäli asiakas peruuttaa ilmoittautumisen viimeistään kaksi viikkoa ennen tapahtuman alkua. Jos peruutus tehdään, kun tapahtuman alkuun on alle kaksi viikkoa, veloitamme 50% maksusta. Maksua ei palauteta, jos asiakas ei ole peruuttanut ilmoittautumistaan. Mikäli peruutus johtuu äkillisestä sairastumisesta, palautetaan maksu lääkärintodistusta vastaan.
            </p>
            <p>
              Asiakas on vastuussa mahdollisten ruoka-allergioidensa ilmoittamisesta tanssikoululle. Pyrimme huomioimaan yleisimmät allergiat.
            </p>
            <p>
              Tapahtuma voidaan peruuttaa järjestäjän toimesta, mikäli ilmoittautuneita ei ole tarvittavaa määrää, tai muusta vastaavanlaisesta syystä. Tapahtuman peruuntumisesta ilmoitetaan viimeistään viikkoa ennen tapahtuman alkua. Maksut palautetaan tapahtuman peruuntuessa järjestäjän toimesta.
            </p>
            <h4>
              TUOTE- JA PERUUTUSEHDOT TIIVISKURSSEILLE JA SELLAISILLE WORKSHOPEILLE, JOIHIN EI SISÄLLY RUOKAILUA
            </h4>
            <p>
              Tiiviskurssi/workshopmaksu (myöhemmin maksu) oikeuttaa osallistumaan kyseiselle tiiviskurssille/workshopille (myöhemmin tapahtuma). Maksulla voi vahvistaa ilmoittautumisen valittuun tapahtumaan.
            </p>
            <p>
              Ilmoittautuminen on peruutettava sähköpostitse osoitteeseen <a target="_blank" href="mailto:school@stepup.fi">school@stepup.fi</a>. Maksu palautetaan kokonaisuudessaan, mikäli peruutus tehdään viimeistään viikkoa ennen tapahtuman alkua. Jos peruutus tehdään, kun tapahtuman alkuun on alle viikko veloitamme 20% maksusta. Maksua ei palauteta, jos asiakas ei ole peruuttanut ilmoittautumistaan. Mikäli peruutus johtuu äkillisestä sairastumisesta, palautetaan maksu lääkärintodistusta vastaan.
            </p>
            <p>
              Tapahtuma voidaan peruuttaa järjestäjän toimesta, mikäli sinne ei ilmoittaudu tarvittavaa määrää oppilaita, tai muusta vastaavanlaisesta syystä. Tapahtuman peruuntumisesta ilmoitetaan viimeistään viisi päivää ennen tapahtuman alkua. Maksut palautetaan tapahtuman peruuntuessa järjestäjän toimesta.
            </p>
            <hr />
            <p>
              <a name="english-terms" id="english-terms"></a>
              The following general conditions apply to all tickets and payments:
            </p>
            <ol>
              <li>All tickets and payments are personal.</li>
              <li>All tickets are valid for a limited time and the validity can only be extended with a medical certificate.</li>
              <li>The validity period of seasonal tickets cannot be extended.</li>
              <li>We do not refund unused payments/tickets.</li>
              <li>Tickets to weekly classes cannot be used to pay for camps, intensive courses, workshops or training programmes.</li>
              <li>Camp, intensive course, workshop and training programme payments cannot be used to pay for weekly classes, unless otherwise stated.</li>
              <li>Training programme payments cannot be used to pay for weekly classes, camps, intensive courses or workshops, unless otherwise stated.</li>
              <li>Since there is no reception at the Tapanila pop-up, it is not possible to buy tickets or pay additional fees there. Consequently, tickets for shorter classes cannot be used for longer classes in Tapanila.</li>
              <li>If the dance school has to be closed due to a pandemic or similar force majeure, purchased tickets will not be refunded, but their validity period will be extended or missed classes will be compensated in another way.</li>
              <li>Enrollments for weekly classes can be confirmed with a single ticket, serial ticket, seasonal ticket or anytime ticket.</li>
              <li>Enrollments for camps, intensive courses and workshops can only be confirmed by paying the camp/course/workshop payments.</li>
            </ol>
            <p>
              Tickets and payments also have product-specific conditions:
            </p>
            <h4>
              PRODUCT-SPECIFIC TERMS OF SINGLE TICKETS
            </h4>
            The single ticket is specified to a certain duration of a class. It is possible to use the ticket also for a shorter class. You can change a single ticket to a longer class ticket by paying the price difference to the reception when you come to the class.
            <p></p>
            The single ticket is valid for 365 days from the date of purchase. You can confirm one enrollment with a single ticket.
            <p></p>
            <h4>
              PRODUCT-SPECIFIC TERMS OF SERIAL TICKETS
            </h4>
            <p>
              The serial ticket is specified to a certain duration of a class. You can use the serial ticket to pay for any class, but for longer classes, you must pay an additional fee at reception. The additional fee is the difference between a single ticket for a shorter class and a single ticket for a longer class.
            </p>
            <p>
              The serial ticket is valid for six months from the day it is used for the first time. The days when the dance school is closed are automatically taken into account in this validity period. However the ticket cannot be valid for more than one year (365 days) from the date of purchase.
            </p>
            <p>
              With a serial ticket, you can confirm as many enrollments as there are classes left on the ticket.
            </p>
            <h4>
              PRODUCT-SPECIFIC TERMS OF SEASONAL TICKETS
            </h4>
            <p>
              The price of a seasonal ticket depends on the duration of the class, the time of purchase and the amount of the student's seasonal tickets. StepUp is selling seasonal tickets only until the first five weeks of the season have passed. During this period, the price of the seasonal ticket goes down every week by half of a single ticket’s price. We grant a personal discount to a person who uses multiple seasonal tickets to the same season. The first seasonal ticket is full price and the discount for other seasonal tickets for the same person for the same season is -15%.
            </p>
            <p>
              The seasonal ticket confirms your enrollment to the class the seasonal ticket applies to. The seasonal ticket covers the class in question until the end of the season, and the enrollment for this class remains valid regardless of the student's absence. The seasonal ticket cannot be transferred from one class to another. By purchasing the seasonal ticket, the student commits to attend the class in question until the end of the season.
            </p>
            <p>
              If the student is absent from the class, he/she has a chance to compensate a maximum of five absences with another class of the same price or cheaper. It is also possible to compensate for the absence with a more expensive class by paying the difference between the single tickets for the classes at the reception upon coming to the class. Absences must be compensated before the end of the season. The five compensating classes are freely available to the customer at a time of their choosing until the end of the season. There are some public holidays during the season when classes are not held (e.g. All Saints' Day, Independence Day, May Day and Easter). The compensating classes of these public holidays are included in the five times mentioned above.
            </p>
            <p>
              Enroll in the compensating class at the reception when you come to the class. In Tapanila, you enroll to the teacher. In order to compensate for more than five absences, a medical certificate must be submitted.
            </p>
            <h4>
              PRODUCT-SPECIFIC TERMS OF ANYTIME TICKETS
            </h4>
            <p>
              With anytime tickets, you can go to all open weekly classes. Seasonal anytime tickets are valid until the end of the season in question. Weekly and monthly anytime tickets are valid for 7 days or 30 days from the date of activation. The days when the dance school is closed are already taken into account in the validity periods of weekly and monthly anytime tickets. However the ticket cannot be valid for more than one year (365 days) from the date of purchase.
            </p>
            <p>
              With an anytime ticket, you can confirm your enrollment to all weekly classes. Seasonal anytime tickets can be reimbursed with a medical certificate for a continuous absence of at least 10 days.
            </p>
            <h4>
              PRODUCT AND CANCELLATION TERMS AND CONDITIONS FOR CAMPS AND WORKSHOPS WHICH INCLUDE FOOD
            </h4>
            <p>
              The camp/workshop payment (later payment) entitles you to participate in that camp/workshop (later event). Enrollment for the event is confirmed with the payment.
            </p>
            <p>
              Enrollment must be cancelled by email to <a target="_blank" href="mailto:school@stepup.fi">school@stepup.fi</a>. The payment will be refunded in full if the customer cancels the enrollment no later than two weeks before the start of the event. If the cancellation is made less than two weeks before the start of the event, we will charge 50% of the payment. The payment will not be refunded if the customer has not cancelled their enrollment. If the cancellation is due to a sudden illness, the payment will be refunded against a medical certificate.
            </p>
            <p>
              The customer is responsible for reporting any food allergies to the dance school. We try to take into account the most common allergies.
            </p>
            <p>
              The event can be cancelled by the organiser if there are not enough enrollments, or for another similar reason. The cancellation of the event will be notified no later than one week before the start of the event. Payments are returned when the event is cancelled by the organiser.
            </p>
            <h4>
              PRODUCT AND CANCELLATION TERMS FOR INTENSIVE COURSES AND WORKSHOPS THAT DO NOT INCLUDE FOOD
            </h4>
            <p>
              The intensive course/workshop payment (later payment) entitles you to participate in that intensive course/workshop (later event). Enrollment for the event is confirmed with the payment.
            </p>
            <p>
              Enrollment must be cancelled by email to <a target="_blank" href="mailto:school@stepup.fi">school@stepup.fi</a>. The payment will be refunded in full if the cancellation is made no later than one week before the start of the event. If the cancellation is made less than a week before the start of the event, we will charge 20% of the payment. The payment will not be refunded if the customer has not canceled their registration. If the cancellation is due to a sudden illness, the payment will be refunded against a medical certificate.
            </p>
            <p>
              The event can be cancelled by the organiser if there are not enough enrollments, or for another similar reason. Cancellation of the event will be notified no later than five days before the start of the event. Payments are returned when the event is cancelled by the organiser.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Terms;
